import _ from "lodash"
import {
  Polygon,
  MultiPolygon,
  LineString,
  Marker,
  Coordinate,
  MultiLineString,
  ui,
} from "maptalks"
import turfCenter from "@turf/center"
import turfBuffer from "@turf/buffer"

import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader"

import {
  GroundLabel,
  Billboard,
  NavigationPath,
  SpriteMarker,
} from "../object3d"
import { getCenterFromGeometry } from "./geometry"
import { createSVGPathFromMarkerSymbol, svgToPng } from "./svg"

const GeometryType = {
  Polygon,
  MultiPolygon,
}

const ORDINAL_HEIGHT = 0 // in version 3D change ORDINAL_HEIGHT for set altitude of geometry

const VENUE_Z_INDEX = 0
const FOOTPRINT_Z_INDEX = 1
const LEVEL_Z_INDEX = 2
const UNIT_Z_INDEX = 3
const FIXTURE_Z_INDEX = 4
const KIOSK_Z_INDEX = 5
const OPENING_Z_INDEX = 3
const SECTION_Z_INDEX = 6
const USER_LOCATION_Z_INDEX = 99
const LAST_LOCATION_Z_INDEX = USER_LOCATION_Z_INDEX - 1

const PREFIX_HIGHLIGHTED_SYMBOL_KEY = "highlighted"
const SPRITE_MARKER_FEATURE = [
  "amenity-atm",
  "amenity-babychanging",
  "amenity-strollerrental",
  "amenity-boardinggate.ferry",
  "amenity-elevator",
  "amenity-escalator",
  "amenity-stairs",
  "amenity-information",
  "amenity-information.transit",
  "amenity-wheelchair",
  "amenity-restroom",
  "amenity-restroom.male",
  "amenity-restroom.female",
  "amenity-restroom.wheelchair",
  "amenity-taxi",
  "amenity-bus",
  "amenity-parking",
  "amenity-parking.bicycle",
  "amenity-parking.motorcycle",
  "amenity-privatelounge",
  "amenity-landmark",
  "amenity-rail.muni",
  "amenity-service",
  "amenity-smokingarea",
  "amenity-ticketing",
  "amenity-meetingpoint",
  "amenity-prayerroom",
  "amenity-firstaid",
  "amenity-ticketing.rail",
  "amenity-exhibit",
  "amenity-mothersroom",
  "amenity-checkin.desk",
  "amenity-baggagestorage",
  "amenity-baggagecarts",
  "amenity-library",
  "amenity-powerchargingstation",
  "amenity-coinlocker",
  "occupant-currencyexchange",
]
const SPRITE_HIGHLIGHT_MARKER_FEATURE = SPRITE_MARKER_FEATURE.map(
  (featCate) =>
    `${PREFIX_HIGHLIGHTED_SYMBOL_KEY}-${featCate.split("-").join(".")}`
)

const FEATURE_TYPE_LOCATION_PATH_OBJS = {
  occupant: {
    kiosk: "properties.kiosk",
    unit: "properties.anchor.properties.unit",
  },
  amenity: {
    kiosk: "properties.kiosk",
    unit: "properties.units[0]",
  },
}

const getAltitude = (properties) =>
  Math.max(0, properties.ordinal * ORDINAL_HEIGHT || 0)

const createRoomUnit = (feature, style, options) => {
  const { geometry, id, properties } = feature
  const { allowOverride, inheritFillColorToLine } = options
  const symbolStyle = { ...style }
  if (allowOverride) _.merge(symbolStyle, properties.style)

  if (inheritFillColorToLine) symbolStyle.lineColor = symbolStyle.polygonFill

  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      id,
      feature_type: "unit",
      category: properties.category,
      altitude: getAltitude(properties),
    },
    symbol: symbolStyle,
    defaultSymbol: symbolStyle,
  })
}
const createAmenityUnit = (feature, style, options = {}) => {
  const { geometry, id, properties } = feature
  const { allowOverride, inheritFillColorToLine } = options
  const symbolStyle = { ...style }
  if (allowOverride) _.merge(symbolStyle, properties.style)
  if (inheritFillColorToLine) symbolStyle.lineColor = symbolStyle.polygonFill

  const area = new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      id,
      feature_type: "unit",
      category: properties.category,
      altitude: getAltitude(properties),
    },
    symbol: symbolStyle,
    defaultSymbol: symbolStyle,
  })
  return area
}

const createNonpublicUnit = (feature, style, options = {}) => {
  const { geometry, id, properties } = feature
  const { allowOverride, inheritFillColorToLine } = options
  const symbolStyle = { ...style }

  if (allowOverride) _.merge(symbolStyle, properties.style)
  if (inheritFillColorToLine) symbolStyle.lineColor = symbolStyle.polygonFill

  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      id,
      feature_type: "unit",
      category: properties.category,
      altitude: getAltitude(properties),
    },
    symbol: {
      ...style,
    },
  })
}
const createWalkwayUnit = (feature, style, options) => {
  const { geometry, id, properties } = feature
  const { allowOverride, inheritFillColorToLine } = options
  const symbolStyle = { ...style }

  if (allowOverride) _.merge(symbolStyle, properties.style)
  if (inheritFillColorToLine) symbolStyle.lineColor = symbolStyle.polygonFill
  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      id,
      feature_type: "unit",
      category: properties.category,
      altitude: getAltitude(properties),
    },
    symbol: {
      ...symbolStyle,
    },
  })
}

const createWaterFixture = (feature, style, options = {}) => {
  const { geometry, properties } = feature
  const { allowOverride, inheritFillColorToLine, zIndex } = options
  const symbolStyle = { ...style }

  if (allowOverride) _.merge(symbolStyle, properties.style)
  if (inheritFillColorToLine) symbolStyle.lineColor = symbolStyle.polygonFill
  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      altitude: getAltitude(properties),
    },
    symbol: {
      ...style,
    },
    zIndex,
  })
}

const createVegetationFixture = (feature, style, options = {}) => {
  const { geometry, properties } = feature
  const { allowOverride, inheritFillColorToLine, zIndex } = options
  const symbolStyle = { ...style }

  if (allowOverride) _.merge(symbolStyle, properties.style)
  if (inheritFillColorToLine) symbolStyle.lineColor = symbolStyle.polygonFill

  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      altitude: getAltitude(properties),
    },
    symbol: {
      ...symbolStyle,
    },
    zIndex,
  })
}

const createObstructionalFixture = (feature, style = {}, options = {}) => {
  const { geometry, properties = {} } = feature
  const { model3d } = properties
  const { allowOverride, inheritFillColorToLine, zIndex } = options
  const symbolStyle = { ...style }
  if (!_.isEmpty(model3d)) return
  if (allowOverride) _.merge(symbolStyle, properties.style)
  if (inheritFillColorToLine) symbolStyle.lineColor = symbolStyle.polygonFill
  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      altitude: getAltitude(properties),
    },
    symbol: symbolStyle,
    defaultSymbol: symbolStyle,
    zIndex,
  })
}

const createVegetationSection = (feature, symbol = {}) => {
  const { geometry, properties } = feature
  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      altitude: getAltitude(properties),
    },
    symbol,
    zIndex: SECTION_Z_INDEX,
  })
}

const creatingSeatingSection = (feature, symbol = {}) => {
  const { geometry, properties } = feature
  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      altitude: getAltitude(properties),
    },
    symbol,
    zIndex: SECTION_Z_INDEX,
  })
}

const creatingDefaultSection = (feature, symbol = {}) => {
  const { geometry, properties } = feature
  try {
    return new GeometryType[geometry.type](geometry.coordinates, {
      properties: {
        altitude: getAltitude(properties),
      },
      symbol,
      zIndex: SECTION_Z_INDEX,
    })
  } catch (error) {
    console.warn(`Error creatingDefaultSection`, geometry.type)
  }
}

const createEatingDrinkingSection = (feature, symbol = {}) => {
  const { geometry, properties } = feature
  return new GeometryType[geometry.type](geometry.coordinates, {
    properties: {
      altitude: getAltitude(properties),
    },
    symbol,
    zIndex: SECTION_Z_INDEX,
  })
}

const createPrincipalOpening = (feature, style, options = {}) => {
  const { geometry, properties } = feature
  const { allowOverride, zIndex } = options
  const symbolStyle = { ...style }
  if (allowOverride) _.merge(symbolStyle, properties.style)

  try {
    return new LineString(geometry.coordinates, {
      properties: {
        altitude: getAltitude(properties),
      },
      symbol: symbolStyle,
      zIndex,
    })
  } catch (error) {
    console.log(`error creating pedestrian principal opening:`, feature)
  }
}
const createPedestrianOpening = (feature, style, options = {}) => {
  const { geometry, properties, id } = feature
  const { allowOverride, zIndex } = options
  const symbolStyle = { ...style }

  if (allowOverride) _.merge(symbolStyle, properties.style)

  try {
    return new LineString(geometry.coordinates, {
      properties: {
        id,
        feature_type: "opening",
        category: properties.category,
        altitude: getAltitude(properties),
      },
      symbol: symbolStyle,
      zIndex,
    })
  } catch (error) {
    console.log(`error creating pedestrian opening:`, feature)
  }
}

const loadModel3d = (model3d, coordinate, threeLayer) => {
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader()
    const { url, properties: modelProperties } = model3d

    loader.load(
      url,
      (gltf) => {
        const object3d = gltf.scene
        // จัดขนาด + scale ที่เหมาะสม
        object3d.rotation.x = _.get(modelProperties, "rotation.x")
        object3d.rotation.y = _.get(modelProperties, "rotation.y")
        object3d.scale.set(...(_.get(modelProperties, "scale") || []))

        const object = threeLayer.toModel(object3d, {
          coordinate,
        })

        object.getObject3d().traverse((child) => {
          if (child.isMesh === true) {
            child.material.transparent = true
            child.material.metalness = 0.1 // set to near 0 because metal requires environment to reflect light to, if there's nothing then metal will become black.
          }
        })

        resolve(object)
      },
      (xhr) => {},
      (error) => {
        reject(error)
      }
    )
  })
}

const create3DModels = async (
  models,
  defaultCoordinate,
  properties,
  threeLayer
) => {
  let modelObjs = []
  for (let j = 0; j < models.length; j++) {
    const model = models[j]
    const positionCoord = _.get(model, "properties.position")
    const coord = positionCoord || defaultCoordinate
    const object = await loadModel3d(model, coord, threeLayer)
    object.properties = properties
    modelObjs.push(object)
  }

  return modelObjs
}

const createExtrudePolygon = (
  geometry,
  threeLayer,
  material,
  height,
  properties = {},
  options
) => {
  const { offset = 0, altitude = 0 } = options
  const offsetGeometry = turfBuffer(geometry, offset, { units: "meters" })
  const object = threeLayer.toExtrudePolygon(
    offsetGeometry,
    { height, async: true, altitude },
    material
  )
  object.properties = properties
  return object
}

const create3DMarker = (
  coordinates,
  options,
  material,
  threeLayer,
  markerProperties
) => {
  return new SpriteMarker(
    coordinates,
    options,
    material,
    threeLayer,
    markerProperties
  )
}

export const createObject3dFromSvgPath = (svgPath, options) => {
  const { extrusion, scale, material, xOffset, yOffset } = options
  const loader = new SVGLoader()
  const svgData = loader.parse(`<svg>
          <path d="${svgPath}" />
      </svg>`)
  const svgGroup = new THREE.Group()

  svgData.paths.forEach((path) => {
    const shapes = SVGLoader.createShapes(path)
    shapes.forEach((shape) => {
      const meshGeometry = new THREE.ExtrudeGeometry(shape, {
        depth: extrusion,
        bevelEnabled: false,
      })
      const mesh = new THREE.Mesh(meshGeometry, material)

      svgGroup.add(mesh)
    })
  })

  const box = new THREE.Box3().setFromObject(svgGroup)
  const size = box.getSize(new THREE.Vector3())

  const defaultYOffset = size.y / -2
  const defaultXOffset = size.x / -2

  // Offset all of group's elements, to center them
  svgGroup.children.forEach((item) => {
    item.position.x = xOffset || defaultXOffset
    item.position.y = yOffset || defaultYOffset
  })
  svgGroup.rotateX(-Math.PI / 2)
  svgGroup.scale.set(scale, scale, scale)
  return svgGroup
}

const getLocationByFeatureType = (feature) => {
  const featureType = _.get(feature, "feature_type")

  if (_.isNil(featureType)) return
  const { unit: unitPath, kiosk: kioskPath } = _.get(
    FEATURE_TYPE_LOCATION_PATH_OBJS,
    featureType,
    {}
  )
  const unit = _.get(feature, unitPath, null)
  const kiosk = _.get(feature, kioskPath, null)
  return kiosk || unit
}

export const getExtrudeConfig = (extrudeConfigObj, featureType, category) =>
  _.get(extrudeConfigObj, `${featureType}.${category || featureType}`)

const getMarkerConfigByFeature = (feature, extrudeConfigObj) => {
  const location = getLocationByFeatureType(feature)
  const { feature_type: featureType } = location || {}

  const { category } = _.get(location, "properties") || {}

  return getExtrudeConfig(
    extrudeConfigObj,
    featureType,
    category || featureType
  )
}

export const createSpriteMaterialByLabelSymbol = (labelSymbol = []) => {
  const material = new THREE.SpriteMaterial()
  try {
    const [base, icon] = labelSymbol
    const { markerWidth: baseWidth = 24 } = base
    const { markerWidth: iconWidth = 24 } = icon
    const viewBoxDimension = Math.max(baseWidth, iconWidth)

    const baseSVG = createSVGPathFromMarkerSymbol(base)
    const iconSVG = createSVGPathFromMarkerSymbol(icon)
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${viewBoxDimension}" height="${viewBoxDimension}">${baseSVG}${iconSVG}</svg>`
    const textureLoader = new THREE.TextureLoader()
    const scaleFactor = 200 / 24 // Scale factor to upscale from 24px to 200px to ensure the resolution is high enough

    svgToPng(svg, scaleFactor).then((png) => {
      const texture = textureLoader.load(png, () => {
        material.map = texture
        material.needsUpdate = true
      })
    })
  } catch (error) {
    console.warn(`Error createSpriteMaterialByLabelSymbol: `, labelSymbol)
  }
  return material
}

export const styledFeatureGenerator = (mapTheme) => {
  const getElementSymbol = (key) => {
    const [featureType] = key.split(".")
    const featureTypeTheme = _.get(mapTheme, `${featureType}.geometry.symbol`)
    if (featureType === key) return featureTypeTheme
    const categoryTheme = _.get(mapTheme, `${key}.geometry.symbol`)
    return _.merge({}, featureTypeTheme, categoryTheme)
  }

  const getLabelSymbol = (key) => {
    const [featureType] = key.split(".")
    const featureTypeTheme = _.get(mapTheme, `${featureType}.label`)
    const categoryTheme = _.get(mapTheme, `${key}.label`)
    const mergedSymbol = _.merge({}, featureTypeTheme, categoryTheme)
    let symbols = _.values(_.map(mergedSymbol, "symbol"))
    const markerIndexToMove = symbols.findIndex(
      ({ elementType }) => elementType === "label.marker"
    )

    // Move the label.marker symbol to the last of array
    if (markerIndexToMove >= 0) {
      const markerSymbolToMove = _.pullAt(symbols, markerIndexToMove)[0]
      symbols.push(markerSymbolToMove)
    }

    return symbols
  }

  const getLabelOptions = (key) => {
    const [featureType] = key.split(".")
    const featureTypeSymbol = _.get(mapTheme, `${featureType}.label`)
    const categorySymbol = _.get(mapTheme, `${key}.label`)
    const mergedSymbol = _.merge({}, featureTypeSymbol, categorySymbol)
    return _.reduce(
      mergedSymbol,
      (acc, symbol) => ({ ...acc, ..._.get(symbol, "options", {}) }),
      {}
    )
  }

  const generateSpriteMarkerMaterial = () => {
    return SPRITE_MARKER_FEATURE.reduce((acc, featCate) => {
      const [featureType, category] = featCate.split("-")
      const key = `${featureType}.${category}`
      const labelSymbol = getLabelSymbol(key)
      const material = createSpriteMaterialByLabelSymbol(labelSymbol)
      return { ...acc, [key]: material }
    }, {})
  }

  const generateSpriteHighlightMarkerOption = () => {
    return SPRITE_HIGHLIGHT_MARKER_FEATURE.reduce((acc, featCate) => {
      const categoryKey = _.last(featCate.split("-")) // Example: highlighted-amenity.atm -> amenity.atm
      const defaultLabelSymbol = getLabelSymbol(categoryKey)
      const highlightLabelSymbol = getLabelSymbol(featCate)
      const [defaultBase, defaultIcon] = defaultLabelSymbol
      const [highlightBase, highlightIcon] = highlightLabelSymbol
      const base = _.merge({}, defaultBase, highlightBase)
      const icon = _.merge({}, defaultIcon, highlightIcon)

      const material = createSpriteMaterialByLabelSymbol([base, icon])
      const options = getLabelOptions(featCate)
      return { ...acc, [featCate]: { material, options } }
    }, {})
  }

  const spriteMarkerMaterialObj = generateSpriteMarkerMaterial()
  const spriteHighlightMarkerOptionObj = generateSpriteHighlightMarkerOption()

  const getElementOptions = (key) => {
    const [featureType] = key.split(".")
    const featureTypeOptions = _.get(
      mapTheme,
      `${featureType}.geometry.options`
    )

    const categoryOptions = _.get(mapTheme, `${key}.geometry.options`)

    return _.merge({}, featureTypeOptions, categoryOptions)
  }

  const createOccupantMarker = (feature, mapConfig) => {
    const markerConfig = getMarkerConfigByFeature(feature, mapConfig)
    const markerHeight = _.get(markerConfig, "height")
    const { properties } = feature
    if (!properties.anchor) return
    const { geometry } = properties?.anchor
    const occupantName = _.isEmpty(properties.short_name)
      ? properties.name
      : properties.short_name

    // Get Label from located unit / kiosk
    const { kiosk, anchor, venue } = properties
    const { unit } = anchor.properties
    let locatedFeature = kiosk || unit || null

    if (locatedFeature) {
      const { feature_type, properties } = locatedFeature
      const { category } = properties
      const locatedLabelOption =
        feature_type === "kiosk"
          ? getLabelOptions(`${feature_type}`)
          : getLabelOptions(`${feature_type}.${category}`)
      const { hidden = false } = locatedLabelOption || {}
      if (hidden) return
    }

    const logoUrl = _.get(properties, "logo.url")
    const renderType =
      (properties.render_type === "Logo" ||
        properties.render_type === "Logo + Name") &&
      logoUrl
        ? properties.render_type
        : "Name"

    const renderPriority = properties.render_priority || 3
    const labelSymbol = getLabelSymbol(`occupant-${_.toLower(renderType)}`)
    const markerSymbol = _.last(labelSymbol)
    const coordinates = _.get(geometry, "coordinates")
    const priorityLabelSymbol = getLabelSymbol(
      `occupant-${_.toLower(renderType)}-${renderPriority}`
    )
    const priorityMarkerSymbol = _.last(priorityLabelSymbol) || {}

    switch (renderType) {
      case "Logo":
        _.set(priorityMarkerSymbol, "markerFile", logoUrl)

        return new Marker(coordinates, {
          properties: {
            altitude: getAltitude(properties) + markerHeight,
          },
          symbol: priorityLabelSymbol,
        })

      case "Logo + Name":
        return new Marker(coordinates, {
          properties: {
            name: occupantName.en,
            altitude: getAltitude(properties) + markerHeight,
          },
          symbol: {
            textName: "{name}",
            markerFile: logoUrl,
            textHaloRadius: {
              stops: [
                [20, 2],
                [21, 3],
              ],
            },
            ...markerSymbol,
            ...priorityMarkerSymbol,
          },
        })
      case "Name":
      default:
        const { reference: venueReference = "" } = venue?.properties || {}
        const symbolType = !_.isEmpty(venueReference)
          ? `occupant-${_.toLower(
              renderType
            )}-${venueReference}.ui.marker.symbol`
          : `occupant-${_.toLower(renderType)}.ui.marker.symbol`
        const symbol = _.get(mapTheme, symbolType, {})
        const prioritySymbol = _.get(
          mapTheme,
          `occupant-${_.toLower(
            renderType
          )}-${renderPriority}.ui.marker.symbol`,
          {}
        )
        const priorityOptions = _.get(
          mapTheme,
          `occupant-${_.toLower(
            renderType
          )}-${renderPriority}.ui.marker.options`,
          {}
        )

        const createStyledElement = (styleObject, content) => {
          const element = document.createElement("div")
          for (const key in styleObject) {
            element.style[key] = styleObject[key]
          }
          element.className = "mtk-occupant-text-marker"
          element.textContent = content
          //! Use outerHTML to return HTML string instead of element object to avoid DOM event warnings from Maptalks.js.
          return element.outerHTML
        }

        const style = { ...symbol, ...prioritySymbol }

        return new ui.UIMarker(coordinates, {
          content: createStyledElement(style, occupantName.en),
          collision: true,
          collisionFadeIn: true,
          altitude: getAltitude(properties) + markerHeight,
          ...priorityOptions,
        })
    }
  }

  const getFeatureProperties = (feature) => {
    const { id, feature_type, properties } = feature
    const { category, ordinal, style = {}, venue } = properties
    const { reference = "" } = venue?.properties || {}
    const elementStyle = getElementSymbol(`${feature_type}.${category}`)
    const { allowOverride } = getElementOptions(`${feature_type}.${category}`)
    if (allowOverride) _.merge(elementStyle, style)
    const { polygonFill: color } = elementStyle
    const featureProperty = {
      id,
      feature_type,
      category: category || feature_type,
      ordinal,
      defaultColor: color,
      venueReference: reference,
    }
    return featureProperty
  }

  return {
    getHilighPolygonalSymbol: (type, venueReference = "") => {
      switch (type) {
        case "MultiPolygon":
        case "Polygon": {
          const featureType = !_.isEmpty(venueReference)
            ? `hilight-polygonal-${venueReference}`
            : "hilight-polygonal"
          return getElementSymbol(featureType)
        }
        case "Point": {
          return getElementSymbol("hilight-marker")
        }
        default:
          break
      }
    },
    getHighlightMarkerSymbol: () => {
      return getElementSymbol("highlighted-marker")
    },
    createVenue: (feature) => {
      const { geometry, feature_type } = feature
      const symbolStyle = getElementSymbol(feature_type)
      return new GeometryType[geometry.type](geometry.coordinates, {
        symbol: {
          ...symbolStyle,
        },
        zIndex: VENUE_Z_INDEX,
      })
    },
    createFootprint: (feature) => {
      const { geometry, id, properties, feature_type } = feature
      const { category } = properties
      const elementStyle = getElementSymbol(`${feature_type}.${category}`)
      const options = getElementOptions(`${feature_type}.${category}`)

      const { allowOverride, inheritFillColorToLine } = options
      const symbolStyle = { ...elementStyle }
      if (allowOverride) _.merge(symbolStyle, properties.style)

      if (inheritFillColorToLine)
        symbolStyle.lineColor = symbolStyle.polygonFill

      return new GeometryType[geometry.type](geometry.coordinates, {
        properties: {
          id,
          feature_type: "footprint",
          category: category,
        },
        symbol: symbolStyle,
        zIndex: FOOTPRINT_Z_INDEX,
      })
    },
    createLevel: (feature) => {
      const { geometry, properties, feature_type } = feature
      const { category } = properties

      const style = getElementSymbol(`${feature_type}.${category}`)
      // const options = getElementOptions(`${feature_type}.${category}`)

      return new GeometryType[geometry.type](geometry.coordinates, {
        properties: {
          altitude: getAltitude(properties),
        },
        symbol: {
          ...style,
        },
        zIndex: LEVEL_Z_INDEX,
      })
    },

    createUnit: (feature) => {
      const {
        feature_type,
        properties: { category },
      } = feature
      const elementStyle = getElementSymbol(`${feature_type}.${category}`)
      const options = getElementOptions(`${feature_type}.${category}`)
      const zIndex = UNIT_Z_INDEX
      switch (feature.properties.category) {
        case "room":
        case "unenclosedarea":
        case "unspecified":
        case "recreation":
          return createRoomUnit(feature, elementStyle, options).setZIndex(
            zIndex
          )
        case "firstaid":
        case "restroom":
        case "restroom.female":
        case "restroom.male":
        case "escalator":
        case "elevator":
        case "stairs":
        case "parking":
        case "smokingarea":
        case "mothersroom":
        case "privatelounge":
          return createAmenityUnit(feature, elementStyle, options).setZIndex(
            zIndex
          )
        case "nonpublic":
        case "opentobelow":
          return createNonpublicUnit(feature, elementStyle, options).setZIndex(
            zIndex
          )
        case "walkway":
        case "footbridge":
          return createWalkwayUnit(feature, elementStyle, options).setZIndex(
            zIndex - 1
          )
        default:
          break
      }
    },

    createAmenity: (feature) => {
      const { geometry, id, properties, feature_type } = feature
      const { category, style } = properties
      const labelSymbol = getLabelSymbol(`${feature_type}.${category}`)
      const { allowOverride } = getLabelOptions(`${feature_type}.${category}`)

      // If element does not have it's own style fall back to style obtained from theme
      const defaultStyle = (allowOverride ? style : labelSymbol) || labelSymbol
      return new Marker(geometry.coordinates, {
        properties: {
          id,
          feature_type,
          category: properties.category,
          altitude: getAltitude(properties),
        },
        symbol: defaultStyle,
        defaultSymbol: defaultStyle,
      })
    },
    createMarker: (feature) => {
      try {
        const { geometry, properties } = feature
        const coordinates = getCenterFromGeometry(geometry)
        const markerSymbol = getElementSymbol("pin-marker")
        return new Marker(coordinates, {
          properties: {
            altitude: getAltitude(properties),
          },
          symbol: markerSymbol,
        })
      } catch (err) {
        console.log(`error creating marker:`, feature)
      }
    },
    createOriginMarker: (feature) => {
      const { id, geometry, properties } = feature
      const coordinates = getCenterFromGeometry(geometry)
      const markerSymbol = getElementSymbol("origin-marker")
      try {
        return new Marker(coordinates, {
          properties: {
            altitude: getAltitude(properties),
          },
          id,
          symbol: markerSymbol,
        })
      } catch (error) {
        console.log(`error creating origin marker:`, feature)
      }
    },
    createDestinationPinMarker: (feature, extrudeConfig = {}) => {
      const destinationMarkerConfig = getMarkerConfigByFeature(
        feature,
        extrudeConfig
      )
      const markerHeight = _.get(destinationMarkerConfig, "height")
      // Get Label from located unit / kiosk
      const { id, properties } = feature
      const geometry =
        _.get(feature, "geometry") ||
        _.get(feature, "properties.anchor.geometry")
      const coordinates = getCenterFromGeometry(geometry)
      const symbol = getElementSymbol("pin-marker")
      try {
        return new Marker(coordinates, {
          properties: {
            altitude: getAltitude(properties) + markerHeight,
          },
          id,
          symbol,
        })
      } catch (error) {
        console.log(`error creating destination marker:`, feature)
      }
    },
    createDestinationLogoMarker: (feature, extrudeConfig = {}) => {
      const destinationMarkerConfig = getMarkerConfigByFeature(
        feature,
        extrudeConfig
      )
      const markerHeight = _.get(destinationMarkerConfig, "height", 0)

      // Get Label from located unit / kiosk
      const { properties, id } = feature
      const { geometry } = properties.anchor
      const logoUrl = _.get(properties, "logo.url")
      const coordinates = getCenterFromGeometry(geometry)
      const [markerBase, markerLabel] = getLabelSymbol(
        "highlighted-logo-marker"
      )
      try {
        return new Marker(coordinates, {
          properties: {
            altitude: getAltitude(properties) + markerHeight,
          },
          id,
          symbol: [markerBase, { ...markerLabel, markerFile: logoUrl }],
        })
      } catch (error) {
        console.log(`error creating destination marker:`, feature)
      }
    },

    createUserLocationMarker: (feature) => {
      try {
        const { geometry, properties } = feature
        const coordinates = getCenterFromGeometry(geometry)
        const symbolStyle = getElementSymbol("user-location") || {}
        const marker = new Marker(coordinates, {
          properties: {
            altitude: getAltitude(properties),
            ordinal: properties.ordinal !== null ? properties.ordinal : null,
          },
          symbol: symbolStyle.start,
          zIndex: USER_LOCATION_Z_INDEX,
        })

        marker.animate(
          {
            symbol: symbolStyle.end,
          },
          {
            repeat: true,
            easing: "upAndDown",
            duration: 1500,
          }
        )
        return marker
      } catch (err) {
        console.log(`error creating marker:`, feature)
      }
    },

    createLastUserLocationMarker: (feature) => {
      try {
        const { geometry, properties } = feature
        const coordinates = getCenterFromGeometry(geometry)
        const symbolStyle = getElementSymbol("last-user-location") || {}
        const options = getElementOptions("last-user-location") || {}
        const marker = new Marker(coordinates, {
          properties: {
            ...options,
            altitude: getAltitude(properties),
            ordinal: properties.ordinal !== null ? properties.ordinal : null,
          },
          symbol: symbolStyle,
          zIndex: LAST_LOCATION_Z_INDEX,
        })

        return marker
      } catch (err) {
        console.log(`error creating marker:`, feature)
      }
    },

    createHighlightOccupantMarker: (feature, mapConfig) => {
      const markerConfig = getMarkerConfigByFeature(feature, mapConfig)
      const markerHeight = _.get(markerConfig, "height")
      const { id, feature_type, properties } = feature
      const markerProperties = {
        ...properties,
        id,
        feature_type,
      }

      if (!properties.anchor) return
      const { geometry } = properties?.anchor
      const coordinates = _.get(geometry, "coordinates")
      const logoUrl = _.get(properties, "logo.url")

      //use pin-marker if no logo
      if (!logoUrl) {
        const symbol = getElementSymbol("pin-marker")
        return new Marker(coordinates, {
          properties: {
            ...markerProperties,
            altitude: getAltitude(properties) + markerHeight,
          },
          symbol,
        })
      }

      const labelSymbol = getLabelSymbol("highlight-occupant-logo")
      const priorityMarkerSymbol = _.last(labelSymbol) || {}
      _.set(priorityMarkerSymbol, "markerFile", logoUrl)
      return new Marker(coordinates, {
        properties: {
          ...markerProperties,
          altitude: getAltitude(properties) + markerHeight,
        },
        symbol: labelSymbol,
      })
    },

    createHighlight2DAmenityMarkerFrom3DMarker: (feature, mapConfig) => {
      const { coordinates, feature_type, category, units, kiosk } = feature

      const amenityLocatedUnit = _.first(units)
      const unitCategory = _.get(
        amenityLocatedUnit,
        "properties.category",
        category
      )
      const unitType = _.get(amenityLocatedUnit, "feature_type", feature_type)
      const unitConfig = getExtrudeConfig(mapConfig, unitType, unitCategory)
      const unitHeight = _.get(unitConfig, "height", 0)
      const kioskType = _.get(kiosk, "feature_type")
      const kioskConfig = getExtrudeConfig(mapConfig, kioskType, kioskType)
      const kioskHeight = _.get(kioskConfig, "height", 0)

      const markerHeight = unitHeight + kioskHeight

      const symbol = getElementSymbol("highlight-amenity-marker")
      return new Marker(coordinates, {
        properties: {
          ...feature,
          altitude: markerHeight,
        },
        symbol,
      })
    },
    createKiosk: (feature) => {
      const { geometry, feature_type, properties, id } = feature
      const {
        properties: { category },
      } = feature
      const elementStyle = getElementSymbol(`${feature_type}.${category}`)
      const options = getElementOptions(
        `${feature_type}.${category}.geometry.options`
      )

      const { allowOverride, inheritFillColorToLine } = options

      const symbolStyle = { ...elementStyle }

      if (allowOverride) _.merge(symbolStyle, properties.style)
      if (inheritFillColorToLine)
        symbolStyle.lineColor = symbolStyle.polygonFill

      return new GeometryType[geometry.type](geometry.coordinates, {
        properties: {
          id,
          feature_type,
          category: "kiosk",
          altitude: getAltitude(properties),
        },
        symbol: symbolStyle,
        defaultSymbol: symbolStyle,
        zIndex: KIOSK_Z_INDEX,
      })
    },

    createSection: (feature) => {
      const { properties, feature_type } = feature
      const { category } = properties
      const elementStyle = getElementSymbol(`${feature_type}.${category}`)
      switch (category) {
        case "eatingdrinking":
          return createEatingDrinkingSection(feature, elementStyle)
        case "vegetation":
          return createVegetationSection(feature, elementStyle)
        case "seating":
          return creatingSeatingSection(feature, elementStyle)
        default:
          return creatingDefaultSection(feature, elementStyle)
      }
    },

    createOccupant: (feature, mapConfig) => {
      return createOccupantMarker(feature, mapConfig)
    },

    createOpening: (feature) => {
      const {
        feature_type,
        properties: { category },
      } = feature
      const elementStyle = getElementSymbol(`${feature_type}.${category}`)
      const options = {
        ...getElementOptions(`${feature_type}.${category}`),
        zIndex: OPENING_Z_INDEX,
      }
      switch (feature.properties.category) {
        case "pedestrian":
          return createPedestrianOpening(feature, elementStyle, options)
        case "pedestrian.principal":
          return createPrincipalOpening(feature, elementStyle, options)
        default:
          break
      }
    },

    createFixture: (feature) => {
      const {
        feature_type,
        properties: { category },
      } = feature
      const elementStyle = getElementSymbol(`${feature_type}.${category}`)
      const options = {
        ...getElementOptions(`${feature_type}.${category}`),
        zIndex: FIXTURE_Z_INDEX,
      }
      switch (feature.properties.category) {
        case "water":
          return createWaterFixture(feature, elementStyle, options)
        case "vegetation":
          return createVegetationFixture(feature, elementStyle, options)
        case "equipment":
        case "obstruction":
        case "stage":
          return createObstructionalFixture(feature, elementStyle, options)
        default:
          break
      }
    },

    createLineStringFromGeometries: (geometries) => {
      const mergedCoordinates = _(geometries)
        .map((geometry) => {
          switch (geometry.type) {
            case "Point":
              return [
                geometry?.getCoordinates
                  ? geometry?.getCoordinates()
                  : new Coordinate(geometry.coordinates),
              ]
            case "Polygon":
              return [
                geometry?.getCenter
                  ? geometry?.getCenter()
                  : new Polygon(geometry.coordinates).getCenter(),
              ]
            case "MultiPolygon":
              return [
                geometry?.getCenter
                  ? geometry?.getCenter()
                  : new MultiPolygon(geometry.coordinates).getCenter(),
              ]
            case "LineString":
            default:
              return geometry?.getCoordinates
                ? geometry?.getCoordinates()
                : new LineString(geometry.coordinates).getCoordinates()
          }
        })
        .flatten()
        .value()
      const stepPathLineSymbol = getElementSymbol("navigation-path")
      const startPathSymbolMarker = getElementSymbol("navigation-path-start")

      const line = new LineString(mergedCoordinates, {
        smoothness: 0.5,
        symbol: [...stepPathLineSymbol, startPathSymbolMarker],
      })

      return line
    },
    create3DStepPath: (feature, threeLayer, option = {}) => {
      const stepPathLineSymbol = getElementSymbol("navigation-path")
      const pathLineSymbol = stepPathLineSymbol[1]
      const pathLineEffect = stepPathLineSymbol[0]
      const lineOptions = {
        color: pathLineSymbol?.lineColor,
        opacity: pathLineSymbol?.lineOpacity,
      }
      const outlineOptions = {
        color: pathLineEffect?.lineColor,
        opacity: pathLineEffect?.lineOpacity,
      }

      return new NavigationPath(
        feature,
        threeLayer,
        getFeatureProperties(feature),
        option,
        lineOptions,
        outlineOptions
      )
    },

    createDecoration: (decoration, options) => {
      const { type, coordinates } = decoration
      const { id, ordinal, symbol } = options
      const formattedProperties = {
        properties: {
          id,
          altitude: getAltitude(options),
          ordinal,
        },
        symbol,
      }
      switch (type) {
        case "Polygon":
          return new Polygon(coordinates, formattedProperties)
        case "MultiPolygon":
          return new MultiPolygon(coordinates, formattedProperties)
        case "LineString":
          return new LineString(coordinates, formattedProperties)
        case "MultiLineString":
          return new MultiLineString(coordinates, formattedProperties)
        default:
          return null
      }
    },

    /** Three JS */
    create3DFootprint: async (feature, threeLayer, options) => {
      const extrudeHeight = _.get(options, "height")
      if (!extrudeHeight) return

      const { properties } = feature
      const footprintProperties = getFeatureProperties(feature)

      const objects = []
      // Use properties.model3d if exists
      if (Array.isArray(properties.model3d) && properties.model3d.length > 0) {
        const models = properties.model3d
        const center = turfCenter(feature)
        const coordinate = _.get(center, "geometry.coordinates")
        for (const model of models) {
          const object = await loadModel3d(model, coordinate, threeLayer)
          object.properties = footprintProperties
          objects.push(object)
        }
      } else {
        const color = footprintProperties.defaultColor
        //skip create extrude polygon if color === "transparent" because three.js can't convert color:"transparent" to material color
        if (color === "transparent") return
        const material = new THREE.MeshPhongMaterial({
          color,
          transparent: true,
        })
        const object = createExtrudePolygon(
          feature.geometry,
          threeLayer,
          material,
          extrudeHeight,
          footprintProperties,
          {}
        )
        objects.push(object)
      }

      return objects
    },
    create3DGroundLabel: (label, threeLayer) => {
      const text = label.properties.name
      const bound = label.geometry.coordinates[0]
      if (_.isNil(bound)) throw new Error("Invalid coordinates")
      const { angle, fillStyle, ...properties } = label.properties

      return new GroundLabel(
        bound,
        { text, angle, fillStyle, ...properties },
        threeLayer
      )
    },
    create3DBillboard: (billboard, threeLayer) => {
      const { id, feature_type, properties } = billboard
      const { logo, altitude, scale } = properties
      const coordinates = getCenterFromGeometry(billboard.geometry)
      const billboardProperties = {
        ...billboard.properties,
        id,
        feature_type,
      }

      const options = {
        altitude,
        scale,
      }

      return new Billboard(
        coordinates,
        options,
        logo?.url,
        threeLayer,
        billboardProperties
      )
    },
    create3DAmenityMarker: (feature, threeLayer, config) => {
      const { geometry, properties, feature_type, id } = feature
      const { category, units, kiosk } = properties

      const amenityLocatedUnit = _.first(units)
      const unitCategory = _.get(
        amenityLocatedUnit,
        "properties.category",
        category
      )
      const unitType = _.get(amenityLocatedUnit, "feature_type", feature_type)
      const unitConfig = getExtrudeConfig(config, unitType, unitCategory)
      const unitHeight = _.get(unitConfig, "height", 0)
      const kioskType = _.get(kiosk, "feature_type")
      const kioskConfig = getExtrudeConfig(config, kioskType, kioskType)
      const kioskHeight = _.get(kioskConfig, "height", 0)

      const coordinates = _.get(geometry, "coordinates")

      const markerProperties = {
        ...properties,
        coordinates,
        id,
        feature_type,
      }

      const material = _.get(
        spriteMarkerMaterialObj,
        `${feature_type}.${category}`
      )

      const highlightOptions = _.get(
        spriteHighlightMarkerOptionObj,
        `${PREFIX_HIGHLIGHTED_SYMBOL_KEY}-${feature_type}.${category}`
      )

      const options = {
        scale: 0.05,
        altitude: unitHeight + kioskHeight,
        highlight: highlightOptions,
      }

      return create3DMarker(
        coordinates,
        options,
        material,
        threeLayer,
        markerProperties
      )
    },

    create3DOccupantAmenityMarker: (feature, threeLayer, config) => {
      const { geometry, properties, feature_type, id } = feature
      const { category, unit, kiosk } = properties
      const amenityLocation = kiosk || unit
      const locationType = _.get(amenityLocation, "feature_type", feature_type)
      const locationCategory =
        locationType === "kiosk"
          ? "kiosk"
          : _.get(amenityLocation, "properties.category", category)
      const locationConfig = getExtrudeConfig(
        config,
        locationType,
        locationCategory
      )
      const coordinates = _.get(geometry, "coordinates")

      const markerProperties = {
        ...properties,
        coordinates,
        id,
        feature_type,
      }

      const options = {
        scale: 0.05,
        altitude: _.get(locationConfig, "height", 0),
      }

      const material = _.get(
        spriteMarkerMaterialObj,
        `${feature_type}.${category}`
      )
      return create3DMarker(
        coordinates,
        options,
        material,
        threeLayer,
        markerProperties
      )
    },
    create3DUnit: async (unit, threeLayer) => {
      const { id, feature_type, properties } = unit
      const { category, ordinal, model3d } = properties

      const modelProperty = {
        id,
        feature_type,
        category,
        ordinal,
      }

      const center = turfCenter(unit)
      const coordinate = _.get(center, "geometry.coordinates")
      const models = await create3DModels(
        model3d,
        coordinate,
        modelProperty,
        threeLayer
      )
      return models
    },

    create3DKiosk: async (kiosk, threeLayer) => {
      const { id, feature_type, properties } = kiosk
      const { ordinal, model3d } = properties

      const modelProperty = {
        id,
        feature_type,
        ordinal,
      }

      const center = turfCenter(kiosk)
      const coordinate = _.get(center, "geometry.coordinates")
      const models = await create3DModels(
        model3d,
        coordinate,
        modelProperty,
        threeLayer
      )
      return models
    },

    createVenue3DModel: async (venue, threeLayer) => {
      const { id, feature_type, properties } = venue
      const { category, model3d } = properties
      const modelProperty = {
        id,
        feature_type,
        category,
      }
      const center = turfCenter(venue)
      const centerCoord = _.get(center, "geometry.coordinates")
      const modelPosition = _.get(model3d, "properties.position", centerCoord)
      const models = await create3DModels(
        model3d,
        modelPosition,
        modelProperty,
        threeLayer
      )
      return models
    },

    create3DFixture: async (fixture, threeLayer) => {
      const { id, feature_type, properties } = fixture
      const { category, ordinal, model3d } = properties

      const modelProperty = {
        id,
        feature_type,
        category,
        ordinal,
      }

      const center = turfCenter(fixture)
      const coordinate = _.get(center, "geometry.coordinates")
      const models = await create3DModels(
        model3d,
        coordinate,
        modelProperty,
        threeLayer
      )
      return models
    },
    createExtrudedUnit: (unit, threeLayer, options) => {
      const extrudeHeight = _.get(options, "height")
      if (!extrudeHeight) return
      //NOTE: this is temporary condition that only extrude when the unit is "room"
      const unitProperty = getFeatureProperties(unit)
      //NOTE: offset will be dynamic in future improvement
      const options3d = {
        // TODO: Move to extrude config later
        offset: -0.1,
        altitude: _.get(options, "altitude", 0),
      }
      const color = unitProperty.defaultColor
      if (color === "transparent") return
      const material = new THREE.MeshPhongMaterial({ color, transparent: true })
      const object = createExtrudePolygon(
        unit.geometry,
        threeLayer,
        material,
        extrudeHeight,
        unitProperty,
        options3d
      )
      return object
    },
    createExtrudedKiosk: (kiosk, threeLayer, options) => {
      const extrudeHeight = _.get(options, "height")
      if (!extrudeHeight) return
      const kioskProperty = getFeatureProperties(kiosk)
      //NOTE: offset will be dynamic in future improvement
      const options3d = {
        offset: -0.1,
        altitude: _.get(options, "altitude", 0),
      }
      const color = kioskProperty.defaultColor
      if (color === "transparent") return
      const material = new THREE.MeshPhongMaterial({ color, transparent: true })
      const object = createExtrudePolygon(
        kiosk.geometry,
        threeLayer,
        material,
        extrudeHeight,
        kioskProperty,
        options3d
      )
      return object
    },
    createExtrudedLevel: (level, threeLayer, options) => {
      const extrudeHeight = _.get(options, "height", 0)
      if (!extrudeHeight) return
      const levelProperty = getFeatureProperties(level)
      const options3d = { offset: 0 }
      const color = levelProperty?.defaultColor || "#808080"
      if (color === "transparent" || _.isEmpty(level?.geometry?.coordinates))
        return
      const material = new THREE.MeshPhongMaterial({ color, transparent: true })
      const object = createExtrudePolygon(
        level.geometry,
        threeLayer,
        material,
        extrudeHeight,
        levelProperty,
        options3d
      )
      return object
    },
    createAmbientLight: (config) => {
      const { color: colorString = "0xffffff", intensity = 1 } = config
      const color = parseInt(colorString, 16)
      const ambientLight = new THREE.AmbientLight(color, intensity)
      return ambientLight
    },
    createDirectionalLight: (config) => {
      const {
        color: colorString = "0xffffff",
        intensity = 1,
        position: positionString = [0, 0, 0],
      } = config
      const color = parseInt(colorString, 16)
      const [x, y, z] = positionString
      const light = new THREE.DirectionalLight(color, intensity)
      light.position.set(x, y, z).normalize()
      return light
    },
  }
}

export const getLocationIdByFeature = (feature) => {
  switch (feature?.feature_type) {
    case "kiosk":
    case "unit":
    case "amenity":
    case "opening":
      return feature.id
    case "occupant":
      const category = _.get(feature, "properties.category")
      if (
        category === "currencyexchange" ||
        category === "bank" ||
        category === "books"
      )
        return feature.id
      return feature.properties?.unit_id
        ? feature.properties?.unit_id
        : feature.properties?.kiosk_id
    case "promotion":
    case "event":
      return feature.properties?.feature_id
    default:
      return
  }
}
