import { Map, TileLayer, VectorLayer, Extent, LineString, ui } from "maptalks"
import _ from "lodash"
import {
  feature as turfFeature,
  featureCollection,
  lineString,
  point,
} from "@turf/helpers"
import turfDistance from "@turf/distance"
import turfCenter from "@turf/center"
import bbox from "@turf/bbox"

import * as THREE from "three"
import { ThreeLayer } from "maptalks.three"

import {
  styledFeatureGenerator,
  getExtrudeConfig,
} from "./utils/createElements"

import { getBearingBetweenPoints } from "./utils/math"
import {
  LAYERS,
  LAYER_OPTIONS,
  LAYER_FEATURE_TYPE_OBJ,
  HIGHLIGHT_LAYER_NAME,
  USER_LOCATION_LAYER_NAME,
} from "./constants"
import TWEEN from "@tweenjs/tween.js"
import { Marker3d, NavigationPath, Billboard, SpriteMarker } from "./object3d"
import {
  createHighlighExtrudeObjectController,
  createHighlighBillboardController,
} from "./utils/createHighlightElement"

const defaultOptions = {
  center: [100.5017051, 13.7572619],
  zoom: 18.5,
  bearing: 0,
  pixelRatio: 1,
}
export class IndoorMap {
  //TODO: refac functions; let them do only 1 thing in a function

  /** Note: "#" means private variables */
  #defaultCenter = defaultOptions.center
  #defaultZoom = defaultOptions.zoom
  #defaultBearing = defaultOptions.bearing
  #styler = null
  #featuresInitted = false
  #elementsLoaded = false
  #elements = {}
  #features = []
  #ordinals = []
  #mapTheme = {}
  #billboards = []
  #marker3DObjects = []
  #billboardObjects = []
  #mapDecorations = []
  #groundLabels = []
  #groundObjects = []
  #navigationGeometries = {}
  #venueObjects = []
  #glbObjects = []
  #objects = []
  #object3ds = []
  #highlightElementIds = []
  #highlightObjectIds = []
  #highlightObjectControllers = []
  #userLocationGeometry = null
  #userLocationElement = null
  #mapConfig = {}

  #lastUserLocationGeometries = []
  #lastUserLocationElements = []

  #zoomLevel = defaultOptions.zoom

  #onClickElement = () => {}
  #animationsToRun = []

  constructor(elementId, options) {
    const {
      center,
      defaultZoom,
      onMapReady,
      onModelReady,
      onMapLoading,
      pixelRatio,
    } = _.merge({}, defaultOptions, options)
    // To be moved to config

    this.#defaultZoom = defaultZoom
    this.#zoomLevel = defaultZoom

    this.map = new Map(elementId, {
      attribution: false,
      center,
      zoom: defaultZoom,
      baseLayer: new TileLayer("base", {
        urlTemplate:
          "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        subdomains: ["a", "b", "c", "d"],
        opacity: 0.6,
        attribution: "",
        hitDetect: false,
        decodeImageInWorker: true,
        errorUrl: "/assets/img/tile-placeholder.png",
      }),
      layers: [],
    })
    this.map.setDevicePixelRatio(pixelRatio)

    this.onMapReady = onMapReady
    this.onMapLoading = onMapLoading
    this.onModelReady = onModelReady
    this.showVenueObject = false
    // Bind events
    this.map.on("zooming", this.handleMapZooming)
    this.map.on("click", this.handleMapClick)
  }

  /**
   * Events
   */

  handleMapZooming = ({ to }) => {
    this.#zoomLevel = to
  }

  handleMapClick = ({ coordinate }) => {
    const { x, y } = coordinate
    console.log(`Coordinates: x: ${x} y: ${y}`)
  }

  /**
   * Getters & Setters
   */
  get defaultBearing() {
    return this.#defaultBearing
  }

  get defaultCenter() {
    return this.#defaultCenter
  }

  get defaultZoom() {
    return this.#defaultZoom
  }

  get elementsLoaded() {
    return this.#elementsLoaded
  }

  get pixelRatio() {
    return this.map.getDevicePixelRatio()
  }

  set mapTheme(value) {
    this.#mapTheme = value
    this.#styler = styledFeatureGenerator(this.#mapTheme)
  }

  get ordinals() {
    return this.#ordinals || []
  }

  set ordinals(value) {
    if (!Array.isArray(value)) throw new Error("ordinals must be Array")
    this.#ordinals = value
  }

  set billboards(value) {
    this.#billboards = value
  }

  set defaultCenter(value) {
    this.#defaultCenter = value
  }
  set defaultBearing(value) {
    this.#defaultBearing = value
  }
  set mapConfig(value) {
    this.#mapConfig = value
  }

  set mapDecorations(value) {
    this.#mapDecorations = value
  }

  set maxZoom(value) {
    this.map.setMaxZoom(value)
    const spatialReference = {
      projection: "EPSG:3857",
      resolutions: (function () {
        const resolutions = []
        const d = 2 * 6378137 * Math.PI
        for (let i = 0; i < value; i++) {
          resolutions[i] = d / (256 * Math.pow(2, i))
        }
        return resolutions
      })(),
    }
    this.map.setSpatialReference(spatialReference)
  }

  set minZoom(value) {
    this.map.setMinZoom(value)
  }

  set pixelRatio(value) {
    this.map.setDevicePixelRatio(value)
  }

  set groundLabels(value) {
    this.#groundLabels = value
  }

  set onClickElement(func) {
    this.#onClickElement = func
  }

  set features(value) {
    /**
     * Setup with features
     * -------------------
     * 1. Create ordinals
     * 2. Init Layers (based on ordinals)
     * 3. Create elements
     */
    if (value.length === 0 || !this.#mapTheme) {
      return
    }

    this.#features = value

    this.ordinals = _(this.#features)
      .filter((f) => f.feature_type === "level")
      .map((f) => f.properties.ordinal)
      .sort()
      .uniq()
      .value()

    if (typeof this.onMapLoading === "function") this.onMapLoading()

    if (this.#featuresInitted) {
      this.#clearElements()
      this.#createElements()
      return
    }
    this.#initMapElements()
  }

  /**
   * Private internal methods
   */

  #clearElements() {
    LAYERS.forEach((layerKey) => {
      this.#clearAllElementOnLayerByName(layerKey)
    })
    const scene = this.threeLayer.getScene()
    if (scene) {
      scene.children = scene.children.filter(
        (children) => children instanceof THREE.PerspectiveCamera
      )
    }
  }

  #createLayers() {
    const map = this.map
    // Clear Existing Layers
    if (!this.ordinals || this.ordinals.length === 0) return
    // Create Layers
    LAYERS.forEach((layerKey) => {
      const layerID = layerKey
      const option = LAYER_OPTIONS[layerKey]
      const layer = new VectorLayer(layerID, option)
      map.addLayer(layer)
    })

    // ThreeJS Layer
    const threeLayer = new ThreeLayer("t_building", {
      forceRenderOnMoving: true,
      forceRenderOnRotating: true,
      animation: true,
      hitDetect: false,
    })
    this.threeLayer = threeLayer
    threeLayer.addTo(this.map)
  }
  #initMapElements() {
    this.#createLayers()
    const threeLayer = this.threeLayer
    if (threeLayer) {
      this.threeLayer.prepareToDraw = async (gl, scene, camera) => {
        await this.#createElements()
      }
    }
  }

  handleClickElement = (...args) => {
    const onClickElement = this.#onClickElement
    if (!_.isFunction(onClickElement)) return
    this.#onClickElement(...args)
  }

  setCenter(center) {
    this.map.setCenter(center)
  }

  async #createElements() {
    const {
      // 2D
      createVenue,
      createLevel,
      createUnit,
      createAmenity,
      createOpening,
      createSection,
      createOccupant,
      createFixture,
      createKiosk,
      createDecoration,
      createFootprint,

      // 3D
      create3DFootprint,
      create3DGroundLabel,
      create3DBillboard,
      create3DUnit,
      create3DKiosk,
      createVenue3DModel,
      createExtrudedUnit,
      createExtrudedLevel,
      create3DFixture,
      createExtrudedKiosk,
      create3DAmenityMarker,
      create3DOccupantAmenityMarker,
      // Light
      createAmbientLight,
      createDirectionalLight,
    } = this.#styler

    let elements = {}
    let object3ds = []

    const scene = this.threeLayer.getScene()
    if (scene) {
      const {
        ambientLight: ambientLightConfig = {},
        directionalLight: directionalLightConfig = {},
      } = _.get(this.#mapConfig, "light", {})

      const ambientLight = createAmbientLight(ambientLightConfig)
      scene.add(ambientLight)

      const light = createDirectionalLight(directionalLightConfig)
      scene.add(light)
    }
    for (const feature of this.#features) {
      try {
        const { feature_type: featureType, properties, id } = feature
        const layerName = _.get(
          LAYER_FEATURE_TYPE_OBJ,
          featureType,
          featureType
        )
        const layer = this.map.getLayer(layerName)
        let geometry
        const category = _.get(feature, "properties.category")

        const extrudeConfig = _.get(this.#mapConfig, "extrude")

        const featureExtrudeConfig = getExtrudeConfig(
          extrudeConfig,
          featureType,
          category
        )

        switch (featureType) {
          case "venue": {
            geometry = createVenue(feature).addTo(layer)
            // Add 3D Model
            const models = await createVenue3DModel(feature, this.threeLayer)
            models.forEach((model) => {
              model.on("click", this.handleClickElement)
              model.hide()
              this.threeLayer.addMesh(model)
              this.#venueObjects.push(model)
            })
            break
          }
          case "level": {
            const extrudedLevel = createExtrudedLevel(
              feature,
              this.threeLayer,
              featureExtrudeConfig
            )

            if (extrudedLevel) {
              extrudedLevel.on("click", this.handleClickElement)
              this.threeLayer.addMesh(extrudedLevel)
              object3ds.push(extrudedLevel)
            } else {
              geometry = createLevel(feature).addTo(layer)
            }
            break
          }
          case "unit": {
            const models = await create3DUnit(feature, this.threeLayer)
            models.forEach((model) => {
              model.on("click", this.handleClickElement)
              // Hide model at first to prevent model blinked appear before hide by ordinal
              model.hide()
              this.threeLayer.addMesh(model)
              this.#glbObjects.push(model)
            })
            const locatedLevel = feature?.properties?.level
            const { feature_type: levelFeatureType } = locatedLevel
            const levelCategory = _.get(locatedLevel, "properties.category")
            const levelExtrudeConfig = getExtrudeConfig(
              extrudeConfig,
              levelFeatureType,
              levelCategory
            )
            const levelHeight = _.get(levelExtrudeConfig, "height", 0)
            const option = { ...featureExtrudeConfig, altitude: levelHeight }
            const extrudedUnit = createExtrudedUnit(
              feature,
              this.threeLayer,
              option
            )

            if (extrudedUnit) {
              extrudedUnit.on("click", this.handleClickElement)
              this.threeLayer.addMesh(extrudedUnit)
              object3ds.push(extrudedUnit)
            } else {
              geometry = createUnit(feature)
                ?.on("click", this.handleClickElement)
                .addTo(layer)
            }
            break
          }
          case "amenity": {
            if (feature.properties.is_featured) {
              const billboardObj = create3DBillboard(feature, this.threeLayer)
              billboardObj?.on("click", this.handleClickElement)
              billboardObj?.hide()
              this.threeLayer.addMesh(billboardObj)
              this.#billboardObjects.push(billboardObj)
              break
            }

            const marker3d = create3DAmenityMarker(
              feature,
              this.threeLayer,
              extrudeConfig
            )?.on("click", this.handleClickElement)
            object3ds.push(marker3d)
            break
          }
          case "opening": {
            geometry = createOpening(feature)
              ?.on("click", this.handleClickElement)
              .addTo(layer)
            break
          }
          case "section": {
            geometry = createSection(feature)?.addTo(layer)
            break
          }
          case "occupant": {
            switch (category) {
              // Create only marker if it is amenity occupant
              case "currencyexchange":
                const markerFeature = {
                  ...feature,
                  geometry: feature.properties?.anchor?.geometry,
                }
                const amenityLayer = this.map.getLayer(`amenity`)

                if (extrudeConfig) {
                  const marker3d = create3DOccupantAmenityMarker(
                    markerFeature,
                    this.threeLayer,
                    extrudeConfig
                  )?.on("click", this.handleClickElement)
                  this.threeLayer.addMesh(marker3d)
                  this.#marker3DObjects.push(marker3d)
                } else {
                  const marker = createAmenity(markerFeature)
                    ?.on("click", this.handleClickElement)
                    .addTo(amenityLayer)
                  geometry = marker
                }
                break
              default:
                geometry = createOccupant(feature, extrudeConfig)
                /**
                 * !Temporary condition; can be removed later if we implement all markers (logo, logo + name, name) to UIMarker.
                 * UIMarker Can only be added to the map after rc.29
                 * https://github.com/maptalks/maptalks.js/releases/tag/v1.0.0-rc.29
                 */
                if (geometry instanceof ui.UIMarker) {
                  geometry?.addTo(this.map)
                } else {
                  geometry?.addTo(layer)
                }
            }
            break
          }
          case "fixture": {
            geometry = createFixture(feature)?.addTo(layer)
            const models = await create3DFixture(feature, this.threeLayer)
            models.forEach((model) => {
              model.on("click", this.handleClickElement)
              model.hide()
              this.threeLayer.addMesh(model)
              this.#glbObjects.push(model)
            })

            break
          }
          case "kiosk": {
            const models = await create3DKiosk(feature, this.threeLayer)
            models.forEach((model) => {
              model.on("click", this.handleClickElement)
              // Hide model at first to prevent model blinked appear before hide by ordinal
              model.hide()
              this.threeLayer.addMesh(model)
              this.#glbObjects.push(model)
            })
            const locatedLevel = feature?.properties?.level
            const { feature_type: levelFeatureType } = locatedLevel
            const levelCategory = _.get(locatedLevel, "properties.category")
            const levelExtrudeConfig = getExtrudeConfig(
              extrudeConfig,
              levelFeatureType,
              levelCategory
            )
            const levelHeight = _.get(levelExtrudeConfig, "height", 0)
            const option = { ...featureExtrudeConfig, altitude: levelHeight }

            const extrudedkiosk = createExtrudedKiosk(
              feature,
              this.threeLayer,
              option
            )
            if (extrudedkiosk) {
              extrudedkiosk.on("click", this.handleClickElement)
              this.threeLayer.addMesh(extrudedkiosk)
              object3ds.push(extrudedkiosk)
            } else {
              geometry = createKiosk(feature)
                ?.on("click", this.handleClickElement)
                .addTo(layer)
            }
            break
          }
          case "footprint": {
            // Added 3D Footprint
            const objects = await create3DFootprint(
              feature,
              this.threeLayer,
              featureExtrudeConfig
            )

            const is3DFootprint = !_.isNil(objects)

            if (is3DFootprint) {
              objects.forEach((object) => {
                object.on("click", () => {
                  const {
                    geometry: { coordinates },
                  } = turfCenter(feature)
                  const zoom = this.#defaultZoom + 1
                  this.flyToAndZoomIn(coordinates, { zoom, pitch: 45 })
                })
                this.threeLayer.addMesh(object)
                object3ds.push(object)
                this.#objects.push(object)
              })
            } else {
              geometry = createFootprint(feature).addTo(layer)
            }
            // Add Footprint Marker
            if (feature.properties.logo) {
              const footprintMarker = create3DBillboard(
                feature,
                this.threeLayer
              )
              this.threeLayer.addMesh(footprintMarker)
              this.#billboardObjects.push(footprintMarker)
            }

            break
          }
          default:
            break
        }
        if (!id) throw new Error(`Property is missing ID, ${id}`)
        elements[id] = { geometry, properties, featureType, feature }
      } catch (err) {
        console.log(err, {
          feature,
          layer: `${feature.feature_type}`,
        })
      }
    }

    this.#groundLabels.forEach((label) => {
      const text = label.properties.name
      try {
        const groundLabel = create3DGroundLabel(label, this.threeLayer)
        groundLabel.hide()
        this.threeLayer.addMesh(groundLabel)
        this.#groundObjects.push(groundLabel)
      } catch (error) {
        console.log("error creating ground label for ", text)
      }
    })

    this.#mapDecorations.forEach((decoration) => {
      try {
        const { id, geometry, properties } = decoration
        const decorationLayer = this.map.getLayer(`base`)
        const createdDecoration = createDecoration(geometry, {
          id,
          ...properties,
        }).addTo(decorationLayer)
        elements[id] = { geometry: createdDecoration, properties }
      } catch (error) {
        console.log(
          "error creating decoration for ",
          decoration.properties.name
        )
      }
    })

    // Initiate Canvas render
    this.render()
    this.#elements = elements
    this.#elementsLoaded = true
    this.#featuresInitted = true
    this.#object3ds = object3ds
    if (typeof this.onMapReady === "function") this.onMapReady()
    if (typeof this.onModelReady === "function") this.onModelReady()
  }

  #clearAllElementOnLayerByName = (layerName) => {
    const layer = this.map.getLayer(layerName)
    if (layer) layer.clear()
  }

  #showGeometriesByOrdinal(ordinal) {
    _(this.#elements)
      .filter((element) => !!_.get(element, "geometry"))
      .forEach(({ featureType, geometry, properties }) => {
        const isExceptFeatureType =
          featureType === "venue" || featureType === "footprint"
        ordinal === null ||
        properties?.ordinal === ordinal ||
        isExceptFeatureType
          ? geometry.show()
          : geometry.hide()
      })
  }

  #showThreeObjectByOrdinal = (ordinal) => {
    const threeLayer = this.threeLayer
    const objectsToAdd = []
    const objects = this.#object3ds || []
    objects?.forEach((baseObject) => {
      const objectOrdinal = _.get(baseObject, "properties.ordinal")
      const featureType = _.get(baseObject, "properties.feature_type")
      const isAdd = _.get(baseObject, "isAdd")
      const show = Boolean(objectOrdinal === ordinal) || featureType === "venue"
      if (show) return objectsToAdd.push(baseObject)
      if (isAdd) return threeLayer.removeMesh(baseObject)
    })
    threeLayer.addMesh(objectsToAdd)
  }

  /**
   * Change Level & animate to path / geometry / view / etc.
   * ================================== */
  changeLevelByOrdinal(ordinal) {
    this.#showGeometriesByOrdinal(ordinal) // show / hide geometry on the ordinal
    this.#showThreeObjectByOrdinal(ordinal)
  }

  #animateflyTo(viewOptions = {}, options = {}, callbackOption) {
    const { start, end } = {
      start: () => {},
      end: () => {},
      ...callbackOption,
    }
    this.map.flyTo(viewOptions, options, (frame) => {
      if (frame.state.playState === "running" && frame.state.progress === 0)
        start(frame)
      if (frame.state.playState === "finished") end(frame)
    })
  }

  getBearing = () => this.map.getBearing()
  getPitch = () => this.map.getPitch()
  getZoom = () => this.map.getZoom()
  setBearing = (bearing) => this.map.setBearing(bearing)

  getFeatureExtent = (feature) => {
    const featureBbox = bbox(feature)
    return new Extent(...featureBbox)
  }

  getExtentCenter = (extent) => {
    return extent.getCenter()
  }

  getExtentZoom = (extent, options = {}) => {
    const { isFraction = false, ...restOptions } = options
    return this.map.getFitZoom(extent, isFraction, restOptions)
  }

  flyTo = (center, options = {}) => {
    const {
      zoom = this.#defaultZoom,
      pitch = 60,
      duration = 600,
      delay = 2000,
      ease = "out",
      bearing = this.getBearing(),
    } = options
    this.#animateflyTo(
      {
        center,
        zoom,
        pitch,
        bearing,
      },
      { delay, duration, ease }
    )
  }
  getLineStringBearing = (feature) => {
    const { geometry } = feature
    const path = new LineString(geometry.coordinates)
    return getBearingBetweenPoints(
      path.getFirstCoordinate(),
      path.getLastCoordinate()
    )
  }

  //map animation
  // animation = {id, callback}
  addAnimations(animation) {
    this.#animationsToRun.push(animation)
  }

  removeAnimationById(id, callback = () => {}) {
    this.#animationsToRun = this.#animationsToRun.filter(
      (animation) => animation.id !== id
    )
    callback(this) // do something after remove it
  }

  clearAnimations() {
    this.#animationsToRun = []
  }
  // Subject to be removed
  flyToAndZoomIn = (centerPoint, options = {}) => {
    const {
      zoom = this.#defaultZoom + 3,
      pitch = 60,
      duration = 600,
      delay = 2000,
      ease = "out",
    } = options
    this.#animateflyTo(
      {
        center: centerPoint,
        zoom,
        pitch,
      },
      { delay, duration, ease }
    )
  }

  /**
   * Hilighting Elements
   * ========================================= */
  // TODO: To consider if we should use setter `set hilightElementIds` instead?
  setHighlightElementIds(targetElementIds, options = {}) {
    const { defaultMarker } = options
    this.setHighlightedObject(targetElementIds)
    return this.setHighlight2DElementIds(targetElementIds, defaultMarker)
  }
  setHighlight2DElementIds(targetElementIds, defaultMarker = true) {
    const {
      createMarker,
      createHighlightOccupantMarker,
      getHilighPolygonalSymbol,
      getHighlightMarkerSymbol,
    } = this.#styler
    const extrudeConfig = _.get(this.#mapConfig, "extrude")
    /**
     * Hilight new elements
     */
    const elementToHilights = targetElementIds
      .map((elemId) => this.#elements[elemId])
      .filter((elem) => elem)
    elementToHilights.forEach((elem) => {
      const { feature, geometry } = elem
      if (!geometry || !feature) return

      const hilightLayer = this.map.getLayer(HIGHLIGHT_LAYER_NAME)
      if (!hilightLayer) return
      const symbol = getHilighPolygonalSymbol(geometry.type)
      switch (geometry.type) {
        case "MultiPolygon":
        case "Polygon": {
          geometry?.updateSymbol(symbol)
          break
        }
        default:
          break
      }

      switch (feature.feature_type) {
        case "amenity":
          const highlightedAmenityMarker = getHighlightMarkerSymbol()
          geometry?.updateSymbol(highlightedAmenityMarker)
          break
        case "occupant": {
          switch (feature.properties.category) {
            case "currencyexchange":
              const highlightedAmenityMarker = getHighlightMarkerSymbol()
              geometry?.updateSymbol(highlightedAmenityMarker)
              break
            default:
              createHighlightOccupantMarker(feature, extrudeConfig)
                .on("click", this.handleClickElement)
                .addTo(hilightLayer)
              break
          }
          break
        }
        default:
          if (defaultMarker) createMarker(feature).addTo(hilightLayer)
          break
      }

      /* Hide the occupant marker before display the highlight marker */
      // const occupantId = IndexedOccupantMarkers[feature.id]
      // if (occupantId) {
      //   hideOccupantMarker(occupantId)
      // }
    })

    // Store new hilightIds to prev ref.
    this.#highlightElementIds = targetElementIds

    if (elementToHilights.length === 0) return
    return featureCollection(
      elementToHilights.map(({ feature }) => {
        const { geometry } = feature
        if (feature.feature_type === "occupant")
          return turfFeature(feature?.properties?.anchor?.geometry)
        return turfFeature(geometry)
      })
    )
  }

  clearHighlightElements() {
    /**
     * Clear previous hilights
     */
    this.#clearAllElementOnLayerByName(HIGHLIGHT_LAYER_NAME)

    //Return geometry to defaulySymbol
    _(this.#highlightElementIds)
      .map((elemId) => this.#elements[elemId]?.geometry)
      .compact()
      .forEach((geometry) => {
        if (geometry instanceof ui.UIMarker) return
        try {
          const defaultSymbol = geometry.options.defaultSymbol
          geometry.updateSymbol(defaultSymbol)
        } catch (err) {
          console.log(
            `error cannot return to defaultSymbol, check if "defaultySymbol" exists in element creation function`
          )
        }
      })
    this.#highlightElementIds = []
  }

  setHighlightedObject(targetObjectIds) {
    const {
      getHilighPolygonalSymbol,
      createHighlight2DAmenityMarkerFrom3DMarker,
    } = this.#styler
    const objects = this.threeLayer?.getBaseObjects()
    const objectsToHighlight = objects.filter(({ properties }) =>
      targetObjectIds.includes(properties?.id)
    )
    const amenityHighlightMode = _.get(
      this.#mapConfig,
      "amenity_highlight_mode",
      ""
    )

    objectsToHighlight.forEach((obj) => {
      //highlight extruded polygon
      if (obj.type === "ExtrudePolygon") {
        const { properties } = obj
        const { venueReference = "" } = properties
        const { polygonFill: color } = getHilighPolygonalSymbol(
          "Polygon",
          venueReference
        )
        const newController = createHighlighExtrudeObjectController(obj, {
          color,
        })
        newController.start()
        this.#highlightObjectControllers.push(newController)
      }
      if (obj instanceof SpriteMarker) {
        if (amenityHighlightMode === "2DMarker") {
          const hilight2DLayer = this.map.getLayer(HIGHLIGHT_LAYER_NAME)
          const extrudeConfig = _.get(this.#mapConfig, "extrude")
          obj.hide()
          const { properties: featureProperties } = obj
          createHighlight2DAmenityMarkerFrom3DMarker(
            featureProperties,
            extrudeConfig
          )
            .on("click", this.handleClickElement)
            .addTo(hilight2DLayer)
        } else {
          obj.highlight()
        }
      }

      if (obj instanceof Billboard) {
        const newController = createHighlighBillboardController(obj)
        newController.start()
        this.#highlightObjectControllers.push(newController)
      }
    })

    this.#highlightObjectIds = targetObjectIds
  }

  clearHighlightObject() {
    this.#highlightObjectControllers.forEach((controller) => {
      if (_.isFunction(controller?.clear)) controller.clear()
    })
    this.#highlightObjectIds.forEach((objIds) => {
      const objects = this.threeLayer?.getBaseObjects()
      const objectToResetHighlight = objects.find(({ properties }) =>
        objIds.includes(properties?.id)
      )
      if (objectToResetHighlight instanceof Marker3d)
        objectToResetHighlight.show()
      if (objectToResetHighlight instanceof SpriteMarker)
        objectToResetHighlight.removeHighlight()
    })
    // Clear the highlight object controllers
    this.#highlightObjectControllers = []
    this.#highlightObjectIds = []
  }

  /**
   * User Location
   ****************************/
  // TODO: To consider if we should use setter `set userLocation` instead?

  addUserLocation(value) {
    const { createUserLocationMarker } = this.#styler

    this.#userLocationGeometry = value

    const { properties, feature_type } = value

    // If UserLocationGeometry not exists, create one.

    const markerLayer = this.map.getLayer(USER_LOCATION_LAYER_NAME)
    if (!this.#userLocationElement?.geometry && markerLayer) {
      const geometry = createUserLocationMarker(value)
      geometry.addTo(markerLayer)

      // Add marker to this.#elements
      const element = {
        geometry,
        properties,
        feature_type,
        feature: value,
      }
      this.#elements["user_location"] = element
      this.#userLocationElement = element
    }
  }

  removeUserLocation() {
    this.#userLocationGeometry = null
    if (this.#userLocationElement) {
      const { geometry } = this.#userLocationElement
      if (geometry) geometry.remove()

      this.#userLocationElement = null
      this.#elements["user_location"] = null
    }
  }

  showUserLocationMarker() {
    this.#userLocationElement?.geometry.show()
  }

  hideUserLocationMarker() {
    this.#userLocationElement?.geometry.hide()
  }

  addLastUserLocation(value) {
    const newLastLocationId = value?.id
    // If the last location ID is undefined or exists, no new marker will be added.
    const isExists =
      this.#lastUserLocationGeometries.findIndex(
        ({ feature }) => feature?.id === newLastLocationId
      ) >= 0

    if (!newLastLocationId || isExists) return

    const { createLastUserLocationMarker } = this.#styler

    this.#lastUserLocationGeometries = [
      ...this.#lastUserLocationGeometries,
      value,
    ]

    const { properties, feature_type } = value

    const markerLayer = this.map.getLayer(USER_LOCATION_LAYER_NAME)

    const geometry = createLastUserLocationMarker(value)
    if (!geometry) return
    geometry.addTo(markerLayer)

    // Add marker to this.#elements
    const element = {
      geometry,
      properties,
      feature_type,
      feature: value,
    }
    this.#elements[`last_user_location-${properties?.id}`] = element
    this.#lastUserLocationElements = [
      ...this.#lastUserLocationElements,
      element,
    ]
  }

  removeLastUserLocation() {
    this.#lastUserLocationGeometries = []
    if (
      this.#lastUserLocationElements &&
      this.#lastUserLocationElements.length > 0
    ) {
      for (const lastUserLocationElem of this.#lastUserLocationElements) {
        const { geometry, properties = {} } = lastUserLocationElem
        if (geometry) {
          geometry.remove()
          delete this.#elements[`last_user_location-${properties?.id}`]
        }
      }
      this.#lastUserLocationElements = []
    }
  }

  hideLastUserLocationMarker() {
    for (const lastUserLocationElem of this.#lastUserLocationElements) {
      const { geometry } = lastUserLocationElem
      if (geometry) geometry.hide()
    }
  }

  /**
   * END of User Location
   ****************************/

  showGeometryByElementId = (elementId) => {
    const geometry = _.get(this.#elements, `${elementId}.geometry`)
    if (geometry) geometry.show()
  }

  hideGeometryByElementId = (elementId) => {
    const geometry = _.get(this.#elements, `${elementId}.geometry`)
    if (geometry) geometry.hide()
  }

  setFeatureObject3DsOpacity = (opacity = 1) => {
    const objects = this.#object3ds
    objects?.forEach((baseObject) => {
      if (baseObject instanceof NavigationPath) return
      baseObject.getObject3d().traverse((child) => {
        if (child.isMesh === true) {
          child.material.opacity = opacity
        }
      })
    })
  }

  /**
   * Navigation
   ****************************/
  combineNearbyLineStrings(
    lineStrings,
    options = { properties: {}, distance: 0.0003 }
    // 0.0003 = 30cm
  ) {
    const { properties = {}, distance = 0.0003 } = options || {}
    const combinedLineStrings = []
    const accLine = []
    if (lineStrings.length === 1) return lineStrings

    for (let i = 0; i < lineStrings.length; i++) {
      const line = lineStrings[i]
      const coords = line.geometry.coordinates
      const prevLine = lineStrings[i - 1]
      const firstCoord = _.first(coords)
      const isFirstLine = i === 0

      if (isFirstLine) {
        accLine.push(...coords)
        continue
      }

      const prevLastCoord = _.last(prevLine.geometry.coordinates)
      const isNearby =
        turfDistance(point(firstCoord), point(prevLastCoord)) < distance

      if (!isNearby) {
        const remainingLines = lineStrings.slice(i)
        const res = this.combineNearbyLineStrings(remainingLines, properties)
        combinedLineStrings.push(...res)
        break
      }
      accLine.push(...coords)
    }
    combinedLineStrings.push(lineString(accLine, properties))
    return combinedLineStrings
  }
  createNavigationGeometries = (stepGeometries, destinationFeature) => {
    const {
      createOriginMarker,
      createDestinationPinMarker,
      createDestinationLogoMarker,
      create3DStepPath,
    } = this.#styler
    const routeMarkerLayer = this.map.getLayer(HIGHLIGHT_LAYER_NAME)

    const linesByOrdinal = _(stepGeometries)
      .filter(({ geometry }) => geometry.type === "LineString")
      .groupBy("properties.ordinal")
      .value()

    const joinedLines = _(linesByOrdinal).reduce((acc, lines, key) => {
      const joined = this.combineNearbyLineStrings(lines, {
        properties: { ordinal: +key },
      })
      return [...acc, ...joined]
    }, [])

    joinedLines.forEach((line, index) => {
      try {
        const navPath = create3DStepPath(line, this.threeLayer)
        this.threeLayer.addMesh(navPath)
        this.#navigationGeometries[`line-${index}`] = navPath
        this.#object3ds.push(navPath)
      } catch (err) {
        console.log(err)
      }
    })

    stepGeometries.forEach((stepGeometry) => {
      const { geometry, properties, element_type = null } = stepGeometry
      let stepElement
      try {
        switch (geometry.type) {
          // Create a destination marker and route path
          case "Point":
            switch (element_type) {
              case "origin-marker":
                stepElement =
                  createOriginMarker(stepGeometry).addTo(routeMarkerLayer)
                break
              case "destination-marker":
                const extrudeConfig = _.get(this.#mapConfig, "extrude")
                switch (destinationFeature.feature_type) {
                  case "occupant":
                    const stepId = _.get(stepGeometry, "id")
                    // !Update step ID to occupant destination feature ID to prevent unexpected clearing of occupant element from map
                    const normalizedDestinationFeature = {
                      ...destinationFeature,
                      id: stepId,
                    }
                    const hasLogo = _.get(
                      normalizedDestinationFeature,
                      "properties.logo.url"
                    )
                    const createOccupantDestinationMarkerFn = hasLogo
                      ? createDestinationLogoMarker
                      : createDestinationPinMarker
                    stepElement = createOccupantDestinationMarkerFn(
                      normalizedDestinationFeature,
                      extrudeConfig
                    ).addTo(routeMarkerLayer)
                    break
                  case "amenity":
                    stepElement = createDestinationPinMarker(
                      destinationFeature,
                      extrudeConfig
                    ).addTo(routeMarkerLayer)
                    break
                  default:
                    stepElement = createDestinationPinMarker(
                      stepGeometry,
                      extrudeConfig
                    ).addTo(routeMarkerLayer)
                    break
                }
                break
              default:
                break
            }
            this.#navigationGeometries[stepGeometry.id] = stepElement
            break
          default:
            break
        }
        if (stepElement) {
          // Add tot Elements object
          this.#elements[stepGeometry.id] = {
            geometry: stepElement,
            properties: { ...stepGeometry, ...properties },
          }
          //
        }
      } catch (err) {
        // console.log(err, {
        //   index: index,
        //   stepGeometries,
        // })
        // throw err;
        console.log(err)
      }
    })
  }

  createOverviewStepPathByOrdinal = (stepGeometries, viewingOrdinal) => {
    const { createLineStringFromGeometries } = this.#styler
    const initialStepGeometries = stepGeometries
      .filter(({ properties }) => properties.ordinal === viewingOrdinal)
      .map(({ geometry }) => geometry)
    return createLineStringFromGeometries(initialStepGeometries)
  }

  clearNavigationGeometries() {
    const objects = this.#navigationGeometries || []

    this.#elements["master-origin-marker"] = null
    this.#elements["master-destination-marker"] = null

    this.#object3ds = this.#object3ds.filter(
      (obj) => !(obj instanceof NavigationPath)
    )

    _.forEach(objects, (obj) => {
      if (!obj) return
      this.#navigationGeometries[obj.properties.id] = null
      obj.remove()
    })
  }
  /**
   * END of Navigation
   ****************************/

  /**
   * hide/show venue 3dmodel
   **/

  hideVenueObjects = () => {
    this.showVenueObject = false
  }
  showVenueObjects = () => {
    this.showVenueObject = true
  }

  /**
   * Other functions
   */

  enableClick = () => this.map.config({ geometryEvents: true })
  disableClick = () => this.map.config({ geometryEvents: false })

  freeze = () => this.map.config({ zoomable: false, draggable: false })
  unfreeze = () => this.map.config({ zoomable: true, draggable: true })

  /**
   * render (frame)
   */
  getTargetViewCenter = (
    targetView,
    options = { offset: { top: 0, left: 0, right: 0, bottom: 0 } }
  ) => {
    // Extend the input targetView with default values from the current view if not provided
    const map = this.map
    const { offset } = options
    const { top = 0, left = 0, right = 0, bottom = 0 } = offset
    // Temporarily set the map to the target view to calculate the extent.
    // This approach requires that the map view can be adjusted without triggering a visible change.
    const originalState = {
      bearing: map.getBearing(),
      center: map.getCenter(),
      pitch: map.getPitch(),
      zoom: map.getZoom(),
    }

    const finalView = {
      bearing: _.isNil(targetView.bearing)
        ? map.getBearing()
        : targetView.bearing,
      center: _.isNil(targetView.center) ? map.getCenter() : targetView.center,
      pitch: _.isNil(targetView.pitch) ? map.getPitch() : targetView.pitch,
      zoom: _.isNil(targetView.zoom) ? map.getZoom() : targetView.zoom,
    }

    map.setView(finalView)

    // Calculate the visible extent at this state
    const projectedTargetCenter = map
      .coordinateToContainerPoint(finalView.center)
      .add(right / 2 - left / 2, bottom / 2 - top / 2)
    const adjustedTargetCenter = map.containerPointToCoordinate(
      projectedTargetCenter
    )
    // Reset the map to its original state to avoid any visible changes
    map.setView(originalState)
    return adjustedTargetCenter
  }

  setMaxExtent(extent) {
    return this.map.setMaxExtent(extent)
  }

  render() {
    // TODO: Update User Location marker position
    const zoomLevel = this.getZoom()
    this.threeLayer._needsUpdate = !this.threeLayer._needsUpdate
    if (this.threeLayer._needsUpdate) {
      this.threeLayer.redraw()
    }

    if (this.threeLayer) {
      const objectOpacity = _.clamp(38 - 2 * zoomLevel, 0, 1)
      this.#objects.forEach((object) => {
        object.getObject3d().traverse((child) => {
          if (child.isMesh) child.material.opacity = objectOpacity
          //TODO: recheck this line if it actually works
          /** set Mesh visible = false when opacity is 0
           * and true when opacity > 0
           */
          child.visible = !!objectOpacity
        })
        object.getObject3d().visible = !!objectOpacity
      })

      if (this.#billboardObjects) {
        this.#billboardObjects.forEach((object) => {
          const objectScale = _.clamp(20 - 1 * zoomLevel, 1, 1.05)
          object.getObject3d().scale.set(objectScale, objectScale, 1)
        })
      }

      const venueObjectOpacity = _.clamp(38 - 2 * zoomLevel, 0, 0.75)
      this.#venueObjects.forEach((object) => {
        object.getObject3d().traverse((child) => {
          if (child.isMesh) child.material.opacity = venueObjectOpacity
          /** set Mesh visible = false when opacity is 0
           * and true when opacity > 0
           */
          child.visible = this.showVenueObject && venueObjectOpacity > 0.4
        })
      })

      // Update bearing to each GroundLabel to determine if text should flip or not
      const view = this.map.getView()
      this.#groundObjects.forEach((gLabel) => {
        gLabel.bearing = view.bearing
      })

      // Update the rotation around the z-axis of the 3D marker to keep it facing the camera.
      const currBearing = this.map.getBearing()
      const radianTowardCamera = Math.abs(
        THREE.MathUtils.degToRad(
          currBearing > 180 ? currBearing : 360 - currBearing
        )
      )
      this.#marker3DObjects.forEach((object) => {
        object.getObject3d().rotation.z = radianTowardCamera
      })
    }

    this.#animationsToRun.forEach(({ callback }) => callback(this))

    //update TWEEN.js animaton
    TWEEN.update()

    requestAnimationFrame(this.render.bind(this))
  }
}
