import _, { set, uniqBy } from "lodash"
import { deepmerge } from "@mui/utils"
import { getCenterFromGeometry } from "utils/geometry"
import { calculateDisplacement } from "./step"
import { point } from "@turf/helpers"

import {
  AnchorData,
  AmenityData,
  Opening,
  Unit,
  Relationship,
  TraversalRelationship,
  ElevatorRelationship,
  StairsRelationship,
  RampRelationship,
  EscalatorRelationship,
} from "../../../types"
import { Graph } from "../type"

import {
  ELEVATOR_DISTANCE,
  ESCALATOR_DISTANCE,
  BASE_POI_DISTANCE,
  STAIR_DISTANCE,
} from "../constants"

type UnitConnection = Record<string, Opening[]>

interface IPrepareSteps {
  relationships: Relationship[]
  openings: Opening[]
  units: Unit[]
  anchors: AnchorData[]
  amenities: AmenityData[]
}

const mergeGraphs = (graphs: Graph[]) => {
  return graphs.reduce(
    (graph, currentGraph) => deepmerge(graph, currentGraph),
    {}
  )
}

const calculateFeatureDistanceWithinUnit = (
  features: (Opening | AnchorData | AmenityData)[],
  option?: undefined | { modifier: number }
): Graph => {
  const { modifier = 0 } = option || { modifier: 0 }
  let relationshipGraph = {}

  for (let currentIndex = 0; currentIndex < features.length; currentIndex++) {
    const isLastItem = currentIndex + 1 === features.length
    if (isLastItem) break // Skip the last item

    for (let j = currentIndex + 1; j < features.length; j++) {
      const opening = features[currentIndex]
      const openingInUnit = features[j]

      try {
        const distance =
          calculateDisplacement(opening.geometry, openingInUnit.geometry, {
            units: "meters",
          }) + modifier

        if (opening.id === openingInUnit.id) continue // Skip if IDs are the same

        set(relationshipGraph, `${opening.id}.${openingInUnit.id}`, distance)
        set(relationshipGraph, `${openingInUnit.id}.${opening.id}`, distance)
      } catch (error) {
        // Error handling: in case of an error, continue to the next iteration
        continue
      }
    }
  }
  return relationshipGraph
}

export const getUnitConnection = (
  units: Unit[],
  relationships: TraversalRelationship[]
): UnitConnection => {
  const openingConnections = {}
  const relationshipMap = new Map()

  // Preprocess relationships for quick access
  relationships.forEach((relationship) => {
    const originId = relationship.properties.origin?.id || null
    const destinationId = relationship.properties.destination?.id || null

    if (!relationshipMap.has(originId)) {
      relationshipMap.set(originId, [])
    }
    if (!relationshipMap.has(destinationId)) {
      relationshipMap.set(destinationId, [])
    }

    relationshipMap.get(originId).push(relationship)
    relationshipMap.get(destinationId).push(relationship)
  })
  // Iterate units and build connections
  units.forEach((unit) => {
    const unitId = unit.id
    const connectedRelations = relationshipMap.get(unitId) || []

    const relationIntermediary = connectedRelations.map(
      (relationship) => relationship.properties.intermediary[0] // Assuming intermediary is always an array
    )

    openingConnections[unitId] = uniqBy(
      [...(openingConnections[unitId] || []), ...relationIntermediary],
      "id"
    )
  })
  return openingConnections
}

const createTraversalLikeGraph = (unitConnects: UnitConnection): Graph => {
  const traversalGraph = _(unitConnects).reduce(
    (graph: Graph, openings: Opening[]) => {
      return deepmerge(graph, calculateFeatureDistanceWithinUnit(openings))
    },
    {}
  )
  return traversalGraph
}

// Case Escalator / Ramp Relationships
const createEscalatorLikeGraph = (relationships: Relationship[]): Graph => {
  return relationships.reduce((acc, relationship) => {
    const {
      properties: { direction, origin, destination },
    } = relationship
    // Initialize graph for current relationship
    const graph = set({}, `${origin.id}.${destination.id}`, ESCALATOR_DISTANCE)
    // Add reverse direction if undirected
    if (direction === "undirected") {
      set(graph, `${destination.id}.${origin.id}`, ESCALATOR_DISTANCE)
    }
    return deepmerge(acc, graph)
  }, {})
}

const createElevatorLikeGraph = (
  elevatorLikeRelationships: ElevatorRelationship[] | StairsRelationship[],
  traversalRelationships: TraversalRelationship[],
  options = { multiplier: 1 }
) => {
  const { multiplier } = options

  const elevatorsArr: Unit[][] = elevatorLikeRelationships.map(
    (relationship: ElevatorRelationship | StairsRelationship) => {
      const { properties } = relationship
      const { origin, intermediary = [], destination } = properties
      return [origin, ...intermediary, destination] as Unit[]
    }
  )

  const elevatorOpenings: Opening[][] = elevatorsArr.map((elevators: Unit[]) =>
    _(elevators)
      .map((elevator) => {
        const relationshipFound = traversalRelationships.find(
          (relationship) => {
            const originId = _.get(relationship, "properties.origin.id")
            const destinationId = _.get(
              relationship,
              "properties.destination.id"
            )
            return [originId, destinationId].includes(elevator.id)
          }
        )
        return relationshipFound
      })
      .compact()
      .map((relationship) => {
        // Find An Opening Connected to the elevator
        return _.first(
          _.get(relationship, "properties.intermediary")
        ) as Opening
      })
      .value()
  )

  // Connect the nodes of the stacking elevator's opening
  // Example
  // elevator-1 connected with opening-1
  // elevator-2 connected with opening-2
  // elevator-3 connected with opening-3
  // Therefore opening-1 should connected with opening-2 and opening-3
  // {
  //   opening-1 {
  //     opening-2 : ELEVATOR_DISTANCE,
  //     opening-3 : ELEVATOR_DISTANCE,
  //   },
  //   opening-2 {
  //     opening-1 : ELEVATOR_DISTANCE,
  //     opening-3 : ELEVATOR_DISTANCE,
  //   },
  //   opening-3 {
  //     opening-1 : ELEVATOR_DISTANCE,
  //     opening-2 : ELEVATOR_DISTANCE,
  //   },
  // }
  const elevatorOpeningSelfConnectedGraph: Graph[] = elevatorOpenings.map(
    (elevatorOpenings) => {
      const elevatorGraph = elevatorOpenings.reduce(
        (acc, opening, currentIndex, array) => {
          if (currentIndex + 1 === array.length) return acc
          const openingToConnect = array.slice(currentIndex + 1)
          const relationshipGraph = openingToConnect.reduce(
            (acc, openingInUnit) => {
              const distance = ELEVATOR_DISTANCE
              if (!opening || !openingInUnit) return acc
              _.set(
                acc,
                `${opening.id}.${openingInUnit.id}`,
                distance * multiplier
              )
              _.set(
                acc,
                `${openingInUnit.id}.${opening.id}`,
                distance * multiplier
              )
              return acc
            },
            {}
          )
          return deepmerge(acc, relationshipGraph)
        },
        {}
      )
      return elevatorGraph
    },
    {}
  )

  return elevatorOpeningSelfConnectedGraph.reduce(
    (graph, elevatorGraph) => deepmerge(graph, elevatorGraph),
    {}
  )
}

export const addPOIDistanceFromOpenings = (
  feature: AnchorData | AmenityData,
  openings: Opening[],
  graph: Graph = {}
) => {
  for (let i = 0; i < openings.length; i++) {
    const opening = openings[i]
    try {
      const { geometry: featureCenterGeometry } = point(
        getCenterFromGeometry(feature.geometry)
      )
      const { geometry: openingCenterGeometry } = point(
        getCenterFromGeometry(opening.geometry)
      )
      const distance =
        calculateDisplacement(featureCenterGeometry, openingCenterGeometry, {
          units: "meters",
        }) + BASE_POI_DISTANCE

      _.set(graph, `${opening.id}.${feature.id}`, distance)
      _.set(graph, `${feature.id}.${opening.id}`, distance)
    } catch (error) {
      console.log("error calculating distance for poi id: " + feature.id)
      continue
    }
  }
  return graph
}

// Anchor
const createAnchorDistanceGraph = (
  anchors: AnchorData[],
  unitOpenings: UnitConnection
): Graph => {
  let graph = {}
  for (let i = 0; i < anchors.length; i++) {
    try {
      const anchor = anchors[i]
      const unit = _.get(anchor, "properties.unit")
      if (!unit) return graph

      const connectedOpenings = unitOpenings[unit.id]
      if (!connectedOpenings) return graph

      addPOIDistanceFromOpenings(anchor, connectedOpenings, graph)
    } catch (error) {
      continue
    }
  }
  return graph
}

const createAmenityDistanceGraph = (
  amenities: AmenityData[],
  unitOpenings: UnitConnection
) => {
  let acc = {}
  for (let i = 0; i < amenities.length; i++) {
    try {
      const amenity = amenities[i]
      const unit = _.get(amenity, "properties.units[0]")
      if (!unit) return acc
      const connectedOpenings = unitOpenings[unit.id]
      if (!connectedOpenings) return acc
      addPOIDistanceFromOpenings(amenity, connectedOpenings, acc)
    } catch (error) {
      continue
    }
  }
  return acc
}
// TODO: Temporary comment for BNS Demo
const createPOIGraph = (
  amenities: AmenityData[],
  anchors: AnchorData[],
  unitConnects: UnitConnection
): Graph => {
  const anchorDistanceGraph = createAnchorDistanceGraph(anchors, unitConnects)
  const amenityDistanceGraph = createAmenityDistanceGraph(
    amenities,
    unitConnects
  )
  return mergeGraphs([anchorDistanceGraph, amenityDistanceGraph])
}

export const prepareSteps = ({
  amenities = [],
  anchors = [],
  relationships = [],
  units = [],
}: IPrepareSteps) => {
  const {
    traversal: traversalRelationships = [],
    escalator: escalatorRelationships = [],
    ramp: rampRelationships = [],
    elevator: elevatorRelationships = [],
    stairs: stairsRelationships = [],
  } = _.groupBy(relationships, "properties.category") as {
    traversal?: TraversalRelationship[]
    escalator?: EscalatorRelationship[]
    ramp?: RampRelationship[]
    elevator?: ElevatorRelationship[]
    stairs?: StairsRelationship[]
  }

  const unitConnects = getUnitConnection(units, traversalRelationships)

  const traversalDistanceGraph = createTraversalLikeGraph(unitConnects)

  const escalatorDistanceGraph = createEscalatorLikeGraph(
    escalatorRelationships
  )

  const rampDistanceGraph = createEscalatorLikeGraph(rampRelationships)

  const elevatorDistanceGraph = createElevatorLikeGraph(
    elevatorRelationships,
    traversalRelationships
  )

  const stairDistanceGraph = createElevatorLikeGraph(
    stairsRelationships,
    traversalRelationships,
    { multiplier: STAIR_DISTANCE }
  )

  const pointOfInterestDistanceGraph = createPOIGraph(
    amenities,
    anchors,
    unitConnects
  )
  return {
    base: mergeGraphs([
      traversalDistanceGraph,
      escalatorDistanceGraph,
      rampDistanceGraph,
      elevatorDistanceGraph,
      stairDistanceGraph,
      pointOfInterestDistanceGraph,
    ]),
    elevator: mergeGraphs([
      traversalDistanceGraph,
      rampDistanceGraph,
      elevatorDistanceGraph,
      pointOfInterestDistanceGraph,
    ]),
  }
}
